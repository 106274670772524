<template>

<div class="main transfer-form" v-if="!loading">
    <h1>Firearm Transfer Form...</h1>
    <template v-if="content.length > 0">
        <div v-for="(paragraph, index) in content" v-html="paragraph" :key="index"></div>
    </template>
    <template v-else>
        <p>There are five different types of firearm transfers available:</p>
        <p><b>Person to Person:</b>  Both the seller and purchaser meet at our store to perform the transfer:  Charge:  $20</p>
        <p><b>{{ getClient.company_name }} to Local FFL dealer:</b>  If you purchase a firearm from us, just pay the shipping fee (approx.: $20-25)</p>
        <p><b>Local FFL dealer to {{ getClient.company_name }}:</b>  Charge:  $35.  No need to alert us, we will contact you when the firearm arrives.</p>
        <p><b>FFL to FFL:</b>  Purchase a firearm from an internet web site?  Complete the Firearm Transfer Form and we’ll take it from there.</p>
        <p><b>Internet sale from a non-FFL:</b> Occasionally, an individual will be selling a firearm on the internet and wants to ship the firearm themselves (not from an FFL).   This method is not preferred.  We recommend that you request they ship it through an FFL dealer. </p>
        <p>If, however, they insist upon shipping it themselves, please have them enclose a bill of sale with:</p>
        <p style="padding: 0 0 0 2em;">Your Name<br>
            Your Phone Number<br>
            Their Name<br>
            Their Phone Number<br>
            Color Copy of their Drivers License
        </p>
        <p>Charge:  $50 for the first firearm and $25 for each additional (from the same seller, in the same transaction)</p>
    </template>
    
    <div class="form">
        <label for="first_name">First Name <span style="color: red;">*</span></label>
        <input type="text" v-model="form.first_name" ref="first_name" class="required" id="first_name">

        <label for="last_name">Last Name <span style="color: red;">*</span></label>
        <input type="text" v-model="form.last_name" ref="last_name" class="required" id="last_name">

        <label for="phone_number">Phone Number <span style="color: red;">*</span></label>
        <input type="text" v-model="form.phone_number" ref="phone_number" class="required" id="phone_number">

        <label for="email_address">Email Address <span style="color: red;">*</span></label>
        <input type="text" v-model="form.email_address" ref="email_address" class="required" id="email_address">

        <label for="make_model">Make and Model of Firearm <span style="color: red;">*</span></label>
        <input type="text" v-model="form.make_model" ref="make_model" class="required" id="make_model">

        <div>
            <label for="ffl_dealer">Is the Seller an FFL Dealer? <span style="color: red;">*</span></label>
            <div style="margin: 0 0 0 1em;">
                <input type="radio" v-model="form.is_ffl_dealer" ref="is_ffl_dealer" value="yes"> Yes &nbsp; <input type="radio" v-model="form.is_ffl_dealer" ref="is_ffl_dealer" value="no"> No<br>
            </div>
        </div>

        <label for="seller_name">Seller's Name <span style="color: red;">*</span></label>
        <input type="text" v-model="form.seller_name" ref="seller_name" class="required" id="seller_name">

        <label for="seller_phone">Seller's Phone Number <span style="color: red;">*</span></label>
        <input type="text" v-model="form.seller_phone" ref="seller_phone" class="required" id="seller_phone">

        <label for="seller_email">Seller's Email Address <span style="color: red;">*</span></label>
        <input type="text" v-model="form.seller_email" ref="seller_email" class="required" id="seller_email">

        <label for="order_number">Order Number (if applicable)</label>
        <input type="text" v-model="form.order_number" ref="order_number" id="order_number">

        <div class="controls">
            <div class="button action" id="send_transfer_form" @click="sendForm"><span class="fas fa-spinner fa-spin" v-if="sendingForm"></span><span v-else>Send Firearm Transfer Form</span></div>
            <div class="message" :class="{ alert: error }" v-if="message">
                <div class="icon">
                    <img src="/img/exclamation-solid.svg" v-if="error">
                    <img src="/img/thumbs-up-regular.svg" v-else>
                </div>
                <strong>
                    <message :message="message"></message>
                </strong>
            </div>
        </div>
    </div>
</div>

<loading class="full-page" v-else></loading>

</template>

<script>
export default {
    data() {
        return {
            loading: true,
            content: [],
            form: {
                first_name: null,
                last_name: null,
                phone_number: null,
                email_address: null,
                make_model: null,
                is_ffl_dealer: null,
                seller_name: null,
                seller_phone: null,
                seller_email: null,
                order_number: null
            },
            required: {
                first_name: "First Name",
                last_name: "Last Name",
                phone_number: "Phone Number",
                email_address: "Email Address",
                make_model: "Make Model",
                seller_name: "Seller Name",
                seller_phone: "Seller Phone",
                seller_email: "Seller Email"
            },
            sendingForm: false,
            message: null,
            error: false
        }
    },
    mounted() {
        this.fetchContent()
    },
    methods: {
        fetchContent() {
            this.apiCall({ destination: 'get_item_frontend_paragraph', data: { paragraph_code: 'TRANSFER_CONTENT' }}).then(obj => {
                this.loading = false
                this.content = obj.paragraph
            }).catch(err => {
                this.loading = false
                console.log(err);
            })
        },
        sendForm() {
            this.error = false
            for (let field in this.required) {
                if (!this.error && !this.form[field]) {
                    this.error = true
                    this.message = this.required[field] + " is a required field"
                    this.$refs[field].classList = "error"
                } else if (this.form[field]) {
                    this.$refs[field].classList = ""
                }
            }
            if (this.error) return
            this.sendingForm = true
            this.apiCall({ destination: 'send_transfer_form', data: this.form }).then(obj => {
                this.sendingForm = false
                this.message = obj.message
            }).catch(err => {
                this.sendingForm = false
                console.log(err);
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.form {
	display: grid;
	grid-auto-flow: row dense;
	input, textarea {
		font-size: 1em;
		padding: 0.5em;
		width: auto;
		margin-bottom: 0.25em;
		resize: none;
		/*border: none;*/
	}
	label {
		display: block;
	}
    input.error {
        border: 1px solid red;
    }
    .controls {
        display: grid;
        grid-template-columns: 1fr 2fr;
        justify-items: left;
    }
}
</style>